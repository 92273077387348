import { useLenis } from "lenis/react";
import { useEffect, useState } from "react";

export default function useNavRetract({ pathname }) {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isLowerThanNav, setIsLowerThanNav] = useState(false);
  const lenis = useLenis();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      if (currentScrollPos > 100) {
        setIsLowerThanNav(true);
      } else {
        setIsLowerThanNav(false);
      }

      setIsHeaderVisible(!isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     try {
  //       lenis?.scrollTo(0);
  //     } catch (e) {
  //       // console.log("error scrolling to top", e);
  //     }
  //   }, 300);
  // }, [pathname]);

  return { isHeaderVisible, isLowerThanNav };
}
