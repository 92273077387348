import React from "react";
import styles from "./NavGuts.module.scss";
import If from "@/components/common/If";
import Link from "next/link";
import { Details } from "../Details";

const NavLink = ({ link = {} }) => {
  if (!link || !link.href) return null;
  return link?.href?.includes("malto:") ? (
    <li className="stack gap-2">
      <a
        href={link.href}
        className="w-full flex justify-between items-center pr-3"
        target="_blank"
      >
        <h2
          className="text-[1.25rem] font-hero text-current"
          dangerouslySetInnerHTML={{ __html: link.title }}
        />
        {/* <span className="">
          <svg
            width="22"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.25"
              y1="0.566987"
              x2="26.5116"
              y2="15.1518"
              stroke="#EAE8E4"
            />
            <line
              x1="0.832031"
              y1="29.567"
              x2="26.0936"
              y2="14.9822"
              stroke="#EAE8E4"
            />
          </svg>
        </span> */}
      </a>
      <If condition={link.description}>
        <span className="text-xs font-thin text-white text-left">
          {link.description}
        </span>
      </If>
    </li>
  ) : (
    <li className="stack gap-2 items-start">
      <Link
        href={link.href}
        className="w-full flex justify-between items-center pr-3"
      >
        <h2
          className="text-[1.25rem] font-hero text-current"
          dangerouslySetInnerHTML={{ __html: link.title }}
        />
        {/* <span className="">
          <svg
            width="22"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.25"
              y1="0.566987"
              x2="26.5116"
              y2="15.1518"
              stroke="#EAE8E4"
            />
            <line
              x1="0.832031"
              y1="29.567"
              x2="26.0936"
              y2="14.9822"
              stroke="#EAE8E4"
            />
          </svg>
        </span> */}
      </Link>
      <If condition={link.description}>
        <span className="text-xs font-thin text-white text-left">
          {link.description}
        </span>
      </If>
    </li>
  );
};

/**
 *
 * NavGuts
 *
 */
const NavGuts = ({ ...props }) => {
  return (
    <div className="nav">
      <Details
        items={[
          {
            title: "Doors By Series",
            description: (
              <>
                <ul className="flex gap-4 flex-wrap">
                  {[
                    {
                      title: "Aspiro<sup>®</sup> Series",
                      href: "/series/aspiro-series",
                    },
                    {
                      title: "Cendura<sup>©</sup> Series",
                      href: "/series/cendura-series",
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          {
            title: "Doors By Category",
            description: (
              <>
                <ul className="flex gap-4 flex-wrap">
                  {[
                    {
                      title: "Flush Wood Veneer",
                      href: "/categories/flush-wood-veneer",
                    },
                    {
                      title: "Flush Paintable",
                      href: "/categories/flush-paintable",
                    },
                    {
                      title: "Flush Laminates",
                      href: "/categories/flush-laminates",
                    },
                    {
                      title: "Stile & Rail",
                      href: "/categories/stile-and-rail-landing",
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          {
            title: "Doors By Market",
            description: (
              <>
                <ul className="flex gap-4 flex-wrap">
                  {[
                    {
                      title: "Healthcare",
                      href: "/markets/healthcare",
                    },
                    {
                      title: "Hosptitality",
                      href: "/markets/hospitality",
                    },
                    {
                      title: "Education",
                      href: "/markets/education",
                    },
                    {
                      title: "Office / Mixed-Use",
                      href: "/markets/office",
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          {
            title: "Doors By Solution",
            description: (
              <>
                <ul className="flex gap-4 flex-wrap">
                  {[
                    {
                      title: "Acoustics",
                      href: "/solutions/acoustics",
                    },
                    {
                      title: "Aesthetics",
                      href: "/solutions/aesthetics",
                    },
                    {
                      title: "Cleanability",
                      href: "/solutions/cleanability",
                    },
                    {
                      title: "Durability",
                      href: "/solutions/durability",
                    },
                    {
                      title: "Safety/Security",
                      href: "/categories/aspiro-defendr-door-system",
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          {
            title: "Baillargeon",
            link: "https://baillargeondoors.com/en/",
            external: true,
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                viewBox="0 0 9600 4800"
              >
                <title>Flag of Canada</title>
                <path
                  fill="#f00"
                  d="m0 0h2400l99 99h4602l99-99h2400v4800h-2400l-99-99h-4602l-99 99H0z"
                />
                <path
                  fill="#fff"
                  d="m2400 0h4800v4800h-4800zm2490 4430-45-863a95 95 0 0 1 111-98l859 151-116-320a65 65 0 0 1 20-73l941-762-212-99a65 65 0 0 1-34-79l186-572-542 115a65 65 0 0 1-73-38l-105-247-423 454a65 65 0 0 1-111-57l204-1052-327 189a65 65 0 0 1-91-27l-332-652-332 652a65 65 0 0 1-91 27l-327-189 204 1052a65 65 0 0 1-111 57l-423-454-105 247a65 65 0 0 1-73 38l-542-115 186 572a65 65 0 0 1-34 79l-212 99 941 762a65 65 0 0 1 20 73l-116 320 859-151a95 95 0 0 1 111 98l-45 863z"
                />
              </svg>
            ),
          },
          {
            title: "Services",
            description: (
              <>
                <ul className="flex gap-4 flex-wrap">
                  {[
                    {
                      title: "USA Wood Door",
                      href: "https://usawooddoor.com/",
                      external: true,
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          // { title: "Components" },
        ]}
      />

      <Details
        items={[
          {
            title: "Resources",
            description: (
              <Details
                items={[
                  {
                    title: "Samples",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            {
                              title: "Order Samples",
                              href: "/samples",
                            },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Quote & Order Forms",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            {
                              title: "Forms & Calculators",
                              href: "/technical-resource/forms-and-calculators",
                            },
                            {
                              title: "Handling Charts",
                              href: "/technical-resource/handling-charts",
                            },
                            {
                              title: "Machining Sheets",
                              href: "/technical-resource/machining-sheets",
                            },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Specification Documents",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            {
                              title: "Specification Checklist",
                              href: "/technical-resource/specification-checklist",
                            },
                            {
                              title: "3-Part Specifications",
                              href: "/technical-resource/3-part-specifications",
                            },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Technical Documents",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            // {
                            //   title: "Specification Checklist",
                            //   href: "/technical-resource/specification-checklist",
                            // },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Training",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            {
                              title: "DoorUniversity",
                              href: "/dooruniversity",
                            },
                            {
                              title: "Continuing Education Units (CEUs)",
                              href: "/continuing-education-units",
                            },
                            {
                              title: "DoorBuilder",
                              href: "/doorbuilder",
                            },
                            // {
                            //   title: "TechTalks",
                            //   href: "/techtalks",
                            // },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Education",
                    description: (
                      <>
                        <ul className="flex gap-4 flex-wrap">
                          {[
                            {
                              title: "Acoustics eBook",
                              href: "#",
                            },
                            {
                              title: "FAQs Veneer Cuts",
                              href: "#",
                            },
                            {
                              title: "Green Standards Glossary",
                              href: "#",
                            },
                            {
                              title: "WDMA QSI",
                              href: "#",
                            },
                            // {
                            //   title: "Blog",
                            //   href: "/blog",
                            // },
                          ].map((link) => (
                            <NavLink link={link} />
                          ))}
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: "Case Studies",
                    link: "/case-studies",
                  },
                  {
                    title: "All Resources",
                    link: "/technical-resources",
                  },
                ]}
              />
            ),
          },
        ]}
      />

      <Details
        items={[
          {
            title: "About",
            link: "/about",
          },
          {
            title: "Contact Us",
            description: (
              <>
                <ul className="stack gap-4">
                  {[
                    {
                      title: "Warranty",
                      href: "/technical-resources/warranty",
                      description:
                        "Activate your warranty or learn more about product handling, care and customer warranty requests.",
                    },
                    {
                      title: "Training and Coutinuing Education",
                      href: "/training",
                      description:
                        "Everything you need to become an expert! From overall product training to mastering and navigating systems.",
                    },
                    {
                      title: "Quote & Order Support",
                      href: "/contact-us/customer-service-contacts",
                      description:
                        "Distributors, please contact us for estimates, orders, quote requests, general or technical questions and more!.",
                    },
                    {
                      title: "Project and Specification Support",
                      href: "mailto:designmanager@masonite.com",
                      description:
                        "Project support and specification assistants for architects, owners and general contractors.",
                    },
                    {
                      title: "Find a Territory Rep",
                      href: "/contact-us/find-a-rep",
                      description: "Find a Territory Rep for your state.",
                    },
                  ].map((link) => (
                    <NavLink key={link.href} link={link} />
                  ))}
                </ul>
              </>
            ),
          },
          { title: "Careers", link: "/careers" },
        ]}
      />
    </div>
  );
};

export default NavGuts;
