export default function Logo({
  color = "#B7974A",
  width = "239",
  className = "",
}) {
  return (
    <>
      <svg
        width={width}
        className={`${className}`}
        viewBox="0 0 803 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_49_2)">
          <path
            d="M248.2 0C240.84 0 233.76 1.52 227.18 4.24C252.24 14.66 270.26 43.12 270.26 76.66C270.26 110.2 252.24 138.66 227.18 149.06C233.76 151.8 240.84 153.3 248.2 153.3C283.6 153.3 312.3 118.98 312.3 76.66C312.3 34.34 283.6 0 248.2 0Z"
            fill={color}
          />
          <path
            d="M418.3 87.3398H419.06C446.36 87.3398 465.54 70.6598 465.54 44.5998C465.54 18.5398 446.36 1.83984 419.06 1.83984C406.9 1.83984 387.81 1.83984 389.38 1.83984C399.68 1.83984 344.36 1.83984 344.36 1.83984V151.46H389.38V87.3598L441.48 151.46H491.44L463.84 114.9C448.46 95.4798 440.26 88.2998 418.3 87.3398ZM389.38 87.1798V2.15984C406.12 5.13984 428.04 17.7598 428.04 44.5998C428.04 71.4398 406.2 84.5198 389.38 87.1798Z"
            fill={color}
          />
          <path
            d="M184.1 76.66C184.1 43.12 202.1 14.66 227.18 4.24C220.6 1.52 213.52 0 206.16 0C170.76 0 142.06 34.32 142.06 76.66C142.06 119 170.76 153.3 206.16 153.3C213.52 153.3 220.6 151.8 227.18 149.06C202.1 138.66 184.1 110.18 184.1 76.66Z"
            fill={color}
          />
          <path d="M101.94 63.4805H45.02L101.94 103.72V63.4805Z" fill={color} />
          <path d="M0 151.46H45.02V63.4798V1.83984H0V151.46Z" fill={color} />
          <path
            d="M118.88 54.0598V1.83984H45.02L118.88 54.0598Z"
            fill={color}
          />
          <path
            d="M663.68 1.83984V151.46H708.72V63.4798V1.83984H663.68Z"
            fill={color}
          />
          <path
            d="M633.18 40.3798V1.83984H585.14L633.18 40.3798Z"
            fill={color}
          />
          <path
            d="M782.56 1.83984H708.72L782.56 54.0598V1.83984Z"
            fill={color}
          />
          <path d="M782.56 106.26L708.72 151.46H782.56V106.26Z" fill={color} />
          <path
            d="M763.24 63.4805H708.72L763.24 102.02V63.4805Z"
            fill={color}
          />
          <path d="M585.14 1.83984H540.1V151.46H585.14V1.83984Z" fill={color} />
          <path
            d="M492.06 40.3798L540.1 1.83984H492.06V40.3798Z"
            fill={color}
          />
          <path
            d="M789.66 4.75992V11.1399H787.8V4.75992H785.32V3.16992H792.15V4.75992H789.66ZM800.75 11.1399V7.41992C800.75 6.45992 800.77 5.49992 800.79 4.54992H800.75C800.48 5.48992 800.24 6.42992 799.97 7.36992L798.87 11.1299H796.74L795.64 7.36992C795.37 6.42992 795.1 5.48992 794.86 4.54992H794.82C794.84 5.48992 794.86 6.44992 794.86 7.41992V11.1399H793.14V3.16992H796.03L796.99 6.49992C797.28 7.45992 797.55 8.46992 797.77 9.45992H797.81C798.03 8.46992 798.3 7.46992 798.59 6.49992L799.58 3.16992H802.47V11.1399H800.75Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_49_2">
            <rect width="802.47" height="153.3" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
